<template>
   <v-row class="d-flex flex-column">
    <v-col cols="12">
      <!-- <v-row class="d-flex justify-center">
        <v-col id="content-wrapper" cols="8">
          <h1 @click="$router.push('/start'),setPage('start')">Mm</h1>
        </v-col>
      </v-row> -->
      <v-row class="d-flex justify-center bg-dblue">
        <v-col cols="8" class="py-16 px-0">
          <h1 class="text-white text-h4 mb-15">NitrosBase Multimodel</h1>
          <p v-if="GET_LANG == 'ru'" class="text-white text-h5 mb-10">Высокопроизводительная универсальная СУБД, обладающая всеми качествами, необходимыми для современных IT решений:</p>
          <p v-if="GET_LANG == 'en'" class="text-white text-h5 mb-10">High-performance universal database for modern IT solutions:</p>
          <v-row class="d-flex">
            <v-col cols="12">
              <v-list class="bg-dblue">
                <v-list-item                   
                  v-for="n in listHead" 
                  :key="n"
                  class="text-white pl-0"
                >
                  <div class="d-flex justify-start">
                    <p class="backV">{{ n }}</p>
                  </div>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="GET_LANG == 'ru'" class="d-flex justify-center">
        <v-col cols="8" class="py-16 px-0">
          <p>
            СУБД NitrosBase включена в Реестр Российского ПО. Реестровая запись №4452 от 16.04.2018 Произведена на основании приказа Министерства цифрового развития, связи и массовых коммуникаций Российской Федерации от 12.04.2018 №157
          </p>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center bg-lgrey">
        <v-col cols="8" class="py-16 px-0">
          <h1 v-if="GET_LANG == 'ru'" class="text-black text-h5 mb-5">Сравнение NitrosBase с графовой СУБД</h1>
          <h1 v-if="GET_LANG == 'en'" class="text-black text-h5 mb-5">NitrosBase and Neo4j performance сomparison</h1>
          <p v-if="GET_LANG == 'ru'">Время на запрос в миллисекундах NitroBase и Neo4j</p>
          <p v-if="GET_LANG == 'en'">Database query execution time (milliseconds)</p>
          <v-row class="pt-10">
            <v-col cols="9" class="pa-0" align-self="end">
              <v-list class="d-flex pa-0 bg-lgrey justify-space-between">
                <v-col cols="2" class="pa-0">
                  <v-list-item>
                    <v-img src="../../assets/multimodel/d1.svg"></v-img>
                  </v-list-item>
                </v-col>
                <v-col cols="2" class="pa-0">
                  <v-list-item>
                    <v-img src="../../assets/multimodel/d2.svg"></v-img>
                  </v-list-item>
                </v-col>
                <v-col cols="2" class="pa-0">
                  <v-list-item>
                    <v-img src="../../assets/multimodel/d3.svg"></v-img>
                  </v-list-item>
                </v-col>
                <v-col cols="2" class="pa-0">
                  <v-list-item>
                    <v-img src="../../assets/multimodel/d4.svg"></v-img>
                  </v-list-item>
                </v-col>
                <v-col cols="2" class="pa-0">
                  <v-list-item>
                    <v-img src="../../assets/multimodel/d4.svg"></v-img>
                  </v-list-item>
                </v-col>
              </v-list>
            </v-col>
            <v-col cols="3" class="d-flex py-1">
              <v-card width="150" class="d-flex flex-column">                
                <v-card-text v-if="GET_LANG == 'ru'" class="pt-3 pb-0">
                  Результаты измерений подтверждены независимым системным интегратором
                </v-card-text>
                <v-card-text v-if="GET_LANG == 'en'" class="pt-3 pb-0">
                  The results have been verified by an independent system integrator
                </v-card-text>
                <v-card-text class="py-0 d-flex flex-column justify-end">
                  <div class="color-container" >
                    <div class="color dark-blue "></div>
                    <span>Neo4j</span>
                  </div>
                  <div class="color-container py-0">
                    <div class="color black "></div>
                    <span>NitrosBase</span>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="8" class="py-16 px-0">
          <v-row>
            <v-col cols="6" class="py-16 px-0">
              <h1 v-if="GET_LANG == 'ru'" class="text-black text-h4 mb-13">Универсальность</h1>
              <h1 v-if="GET_LANG == 'en'" class="text-black text-h4 mb-13">Versatility</h1>
              <v-list class="d-flex flex-column justify-space-between pa-0 w-75">
                <v-list-item 
                  v-for="n in versatilityList" 
                  :key="n"
                  class="pl-0"
                >
                  <p class="props-feature" style="color: #444455; font-weight: 300;">
                    {{ n }}
                  </p>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="6" class="py-16 px-0">
              <h1 v-if="GET_LANG == 'ru'" class="text-black text-h4 mb-13">Масштабируемость, надежность</h1>
              <h1 v-if="GET_LANG == 'en'" class="text-black text-h4 mb-13">Scalability, reliability</h1>
              <v-list class="d-flex flex-column justify-space-between pa-0 w-75">
                <v-list-item 
                  v-for="n in scalabilityList" 
                  :key="n"
                  class="pl-0"
                >
                  <p class="props-feature" style="color: #444455; font-weight: 300;">
                    {{ n }}
                  </p>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>          
        </v-col>
      </v-row>
      
      <v-row class="d-flex justify-center bg-lgrey">
        <v-col cols="8" class="py-16 px-0">              
          <h1 v-if="GET_LANG == 'ru'" class="text-h4">Архитектура</h1>
          <p v-if="GET_LANG == 'ru'" class="mt-10">NitrosBase позволяет создавать единое хранилище для реляционных данных, графов и JSON-документов. Над этими данными Вы можете выполнять SQL, Graph-SQL и JSON запросы.</p>   
          <h1  v-if="GET_LANG == 'en'" class="text-h4">Architecture</h1>
          <p  v-if="GET_LANG == 'en'" class="mt-10">NitrosBase supports a single repository for relational data, graphs and JSON documents. You can perform SQL, Graph-SQL and JSON queries on the same data.</p>   
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="8" class="pt-16 px-0 bg-img">
          <h1 v-if="GET_LANG == 'ru'" class="text-h4">Реляционная модель</h1>
          <h1 v-if="GET_LANG == 'en'" class="text-h4">Relational model</h1>
          <p v-if="GET_LANG == 'ru'" class="mt-7">Архитектура NitrosBase Multimodel разработана для полной поддержки реляционной модели данных и языка SQL. Для этой модели данные представлены в табличном виде.</p>
          <p v-if="GET_LANG == 'en'" class="mt-7">The NitrosBase Multimodel architecture is designed to support the relational data model and SQL. Relational model represents data in tabular form.</p>
          <v-img src="../../assets/multimodel/image1_2.png" class="mt-13" width="60%"></v-img>
          <v-sheet width="35%" class="bg-lgrey mt-16" color="#F1F3FA">
            <div class="d-flex flex-column pa-5">
              <p>SQL query</p>
              <br>
              <p>select</p>
              <p>&nbsp;&nbsp; model, year, name</p>
              <p>from</p>
              <p>&nbsp;&nbsp; car join person on</p>
              <p>&nbsp;&nbsp; car.owner = person.id</p>
            </div>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="8" class="pt-16 px-0">
          <h1 v-if="GET_LANG == 'ru'" class="text-h4">Работа с графами</h1>
          <h1 v-if="GET_LANG == 'en'" class="text-h4">Graph model</h1>
          <p v-if="GET_LANG == 'ru'" class="mt-7">Для представления графовых данных используются специализированные структуры. При маппинге графовых данных, узлами графа являются записи в реляционных таблицах. Реляционные связи Foreign Key -> Primary Key автоматически могут быть интерпретированы как ребра графа. Промежуточные таблицы для связей многие ко многим также видны как ребра графа. Специальные структуры существенно ускоряют обработку графовых запросов, а также SQL JOIN запросов через промежуточные таблицы.</p>
          <p v-if="GET_LANG == 'en'" class="mt-7">Specialized structures are designed to represent graph data. When mapping graph data, the nodes are records in the relational tables. Relational links Foreign Key -> Primary Key can automatically be interpreted as the graph edges. The intermediate tables for many-to-many relationships are also visible as graph edges. Special indices significantly accelerate the graph query processing, as well as SQL JOIN operations.</p>
          <div class="d-flex mt-15">
            <v-img src="../../assets/multimodel/image2.png" width="50%"></v-img>
            <v-sheet width="50%" height="30%" color="#306996">
              <p v-if="GET_LANG == 'ru'" class="pa-5">
                Для работы с графами предназначен специализированный графовый язык Graph-SQL. Но поскольку граф может быть виден и как реляционные данные, то для простых запросов к графовым данным также может использоваться язык SQL.
              </p>
              <p v-if="GET_LANG == 'en'" class="pa-5">
                The Graph-SQL language is developed for graph data processing. But since the graph can also be presented as relational data, SQL can be used for graph data queries.
              </p>
            </v-sheet>
          </div>
          <div class="d-flex mt-7 justify-space-between">
            <v-sheet width="49%" color="#F1F3FA">
              <div class="d-flex flex-column pa-5">
                <p>Graph-SQL query</p>
                <br>
                <p>SELECT p1.name, p2.name as friendname, c1.model</p>
                <p>MATCH (car c1)-[owner]->(person p1)-[friends]->(person p2)&lt;-[owner]-(car c2)</p>
                <p>WHERE c1.model = c2.model</p>
              </div>
            </v-sheet>
            <v-sheet width="49%" color="#F1F3FA">
              <div class="d-flex flex-column pa-5">
                <p>SQL query</p>
                <br>
                <p>SELECT p1.name, p2.name as friendname, c1.model</p>
                <br>
                <p>FROM car c1</p>
                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;join person p1 on c1.owner = p1.id</p>
                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;join friends f on f.fromid = p1.id</p>
                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;join person p2 on f.toid = p2.id</p>
                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;join car c2 on c2.owner = p2.id</p>
                <p>WHERE c1.model = c2.model</p>
              </div>
            </v-sheet>
          </div>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="8" class="py-16 px-0 bg-img-json">
          <h1 v-if="GET_LANG == 'ru'" class="text-h4">JSON-документы</h1>
          <h1 v-if="GET_LANG == 'en'" class="text-h4">JSON documents</h1>
          <p v-if="GET_LANG == 'ru'" class="mt-7">JSON данные автоматически представляются в виде записей и связей, отражающих вложенность объектов или связи между объектами.</p>
          <p v-if="GET_LANG == 'en'" class="mt-7">JSON documents are automatically represented as  records and relationships reflecting the nesting of objects or relationships between objects.</p>
          <div class="d-flex mt-15 ga-15">
            <v-img src="../../assets/multimodel/image4.png" width="50%"></v-img>
            <div class="d-flex flex-column w-50 ga-7">
              <v-sheet color="#306996">
                <p v-if="GET_LANG == 'ru'" class="pa-5">
                  При добавлении этого документа он автоматически представляется в реляционном виде как набор записей в разных таблицах, а также как часть графа.
                </p>
                <p v-if="GET_LANG == 'en'" class="pa-5">
                  When you add this document, it is automatically presented in a relational form as a set of records in some tables, and also as part of a graph.
                </p>
              </v-sheet>
              <v-sheet color="#306996">
                <p v-if="GET_LANG == 'ru'" class="pa-5">
                  Для обработки данных, полученных в виде JSON, можно использовать те же SQL и Graph-SQL запросы.
                </p>
                <p v-if="GET_LANG == 'en'" class="pa-5">
                  The same SQL and Graph-SQL queries can be used to process data received as JSON..
                </p>
              </v-sheet>
            </div>
          </div>
          <v-sheet width="50%" class="bg-lgrey mt-16" color="#F1F3FA">
            <div class="d-flex flex-column pa-5">
              <p>JSON документ</p>
              <br>
              <br>
              <p>{</p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;"id":1,</p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;"model": "Chevrolet",</p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;"owner": {</p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"type": "person",</p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"id": 1,</p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"name": "John,</p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"city": "London",</p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"friends": [ 2, 3 </p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;}</p>
              <p>}</p>
            </div>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center bg-lgrey">
        <v-col cols="8" class="py-16 px-0">
          <formContact />
        </v-col>
      </v-row>
    </v-col>
   </v-row>
 
</template>

<script>
import formContact from "../../components/formContact/formContact.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { formContact },
  name: 'mm',
  methods: {
  ...mapActions(['setPage']),
  // changePage(page) {
  //   this.$store.commit('SET_PAGE',page)
  // }
  },
  computed: {
    ...mapGetters(['GET_LANG','GET_PAGE']),
    listHead() {
      return this.GET_LANG == 'en' ? this.listNamesEn : this.listNames
    },
    versatilityList() {
      return this.GET_LANG == 'en' ? this.versatilityEn : this.versatility
    },
    scalabilityList() {
      return this.GET_LANG == 'en' ? this.scalabilityEn : this.scalability
    },
  },
  data() {
    return {
      listNames: [
        'Поддержка реляционной, графовой, документной модели данных',
        'Высокая производительность на всех поддерживаемых моделях данных',
        'Масштабируемость, надежность',
      ],
      listNamesEn: [
        'Support for relational, graph and document data models',
        'High performance on any data model',
        'Scalability, reliability'
      ],
      versatility: [
        'Работа с реляционными (SQL), графовыми (Graph-SQL), документными (JSON) данными.',
        'Быстрый отклик на всех моделях данных. Единое хранилище для всех данных.',
        'Возможность выполнять SQL, графовые и JSON запросы к одним и тем же данным.',
        'Маппинг, встроенный в ядро NitrosBase, позволяющий переводить объекты из одной модели данных в другую.'
      ],
      versatilityEn: [
        'Relational (SQL), graph (Graph-SQL) and documentary (JSON) data processing.',
        'Fast response on all data models.',
        'SQL, Graph-SQL and JSON queries to the same data.',
        'Mapping built into the core allows for data translation between the models.'
      ],
      scalability: [
        'Высокопроизводительный кластер NitrosBase.',
        'Распределенные запросы на всех моделях данных, быстрая обработка распределенных графовых и JOIN запросов',
        'Высокая доступность и отказоустойчивость',
        'Fault-tolerance сервер позволяет выполнять запросы без задержек при падении одного из узлов кластера'
      ],
      scalabilityEn: [
        'High-performance NitrosBase cluster',
        'High-performance distributed queries on any data: distributed graph, relational JOIN queries', 
        'High availability and fault tolerance',
        'Fault-tolerance server keeps the queries running when some of the cluster nodes fail'
      ]
    }
  }  
}
</script>

<style scoped lang="scss">
  .backV {
    background-size:20px
  }
  .color-container {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
    .black {
      background: #1A1835;
    }
    .dark-blue {
      background: #198CBF;
    }
    .color {
      border-radius: 2px;
      margin-right: 17px;
      width: 15px;
      height: 15px;
    }
    span {
      font-size: 17px;
      line-height: 26px;
      color: #150232;
    }
  }
  .props-feature {
    padding-left: 20px;
    background-image: url('../../assets/multimodel/blueDot.svg');
    background-position: left center;
    background-repeat: no-repeat;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: #1A1835;
  }
  .bg-img {
    background-image: url("../../assets/multimodel/bg3.svg");
    background-position: right bottom 64px;
    background-repeat: no-repeat;
    @media (max-width: 992px) {
      background-size: 63%;
    }
  }
  .bg-img-json {
    background-image: url("../../assets/multimodel/bg4.svg");
    background-position: right bottom;
    background-repeat: no-repeat;
    @media (max-width: 1760px) {
      background-size: 50%;
    }
    // @media (max-width: 992px) {
    //   background-size: 50%;
    // }
  }
  .w-47 {
    width: 45%;
  }
</style>